// @flow

import { isNull, isNil, omitBy, get } from 'lodash-es'
import axios from 'axios'

import { Convert } from '../../utils/convert'
import BrowserStorage from '../../utils/browserStorage'
import AppConfig from '../../config'
import { MARKETPLACE_AUTH_ERROR } from '../variables'

const defaultApi = AppConfig.defaultApi

const parseResponse = response => {
  const status = response.status

  if (status === 204) {
    return { data: {} }
  } else if (status == 202) {
    const id = get(response, ['data', 'id'], null)
    const eventId = get(response, ['data', 'event_id'], null)
    const name = get(response, ['data', 'name'], null)
    const task_id = get(response, ['data', 'task_id'], null)
    const updated = get(response, ['data', 'updated'], null)

    return { id, checking: true, eventId, name, task_id, updated }
  } else if (status >= 200 && status < 300) {
    return response.data
  }

  return null
}

const setToken = response => {
  const key = get(response, 'key')

  if (key) {
    BrowserStorage.set('token', key)
  }

  return response
}

export const getApiUrl = (): string => {
  if (process.env.NODE_ENV !== 'development') {
    return defaultApi
  }

  return BrowserStorage.get('api') || defaultApi
}

const getFullUrl = (endpoint, apiUrl) =>
  endpoint.indexOf(apiUrl) === -1 ? apiUrl + endpoint : endpoint

export const paramsToQuery = (params, duplicateParams) => {
  const filtered = omitBy(params, p => isNil(p))

  return Convert.obj2params(filtered, false, duplicateParams)
}

const callTxtApi = endpoint => {
  const apiUrl = getApiUrl()
  const fullUrl = getFullUrl(endpoint, apiUrl)

  return axios({
    url: fullUrl,
  }).then(parseResponse)
}

const callApi = (
  endpoint,
  method,
  params = null,
  headers = {},
  duplicateParams
) => {
  const apiUrl = getApiUrl()
  let fullUrl = getFullUrl(endpoint, apiUrl)

  const query = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      ...headers,
    },
  }
  const token = BrowserStorage.get('token') || null

  if (!isNull(token)) {
    query.headers.Authorization = `Token ${token}`
  }

  if (params !== null) {
    switch (method) {
      case 'GET': {
        // because ie is crazy at cache xhr get requests (remove in 2020 march)
        query.headers['Cache-Control'] =
          'no-cache,no-store,must-revalidate,max-age=-1,private;'
        fullUrl += `?${paramsToQuery(params, duplicateParams)}`
        break
      }
      default:
        query.body = Convert.obj2params(params)
    }
  }

  return axios({
    url: fullUrl,
    headers: query.headers,
    method,
    data: query.body,
  })
    .then(parseResponse)
    .then(setToken)
    .catch(data => {
      const response = get(data, 'response')
      const error = get(response, ['data', 'errors'])
      const status = get(response, 'status')

      const marketplaceError = error === MARKETPLACE_AUTH_ERROR

      if (
        status === 401 &&
        !marketplaceError &&
        !/login/.test(window.location.pathname)
      ) {
        BrowserStorage.remove('token')
        window.location = '/login'
      } else {
        const error = new Error()
        error.message = marketplaceError ? MARKETPLACE_AUTH_ERROR : data
        throw error
      }
    })
}

const callApiRaw = (endpoint, method, params = null) => {
  const apiUrl = getApiUrl()
  const fullUrl = getFullUrl(endpoint, apiUrl)
  const query = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const token = BrowserStorage.get('token') || null

  if (!isNull(token)) {
    query.headers.Authorization = `Token ${token}`
  }

  if (params !== null) {
    query.body = JSON.stringify(params)
  }

  return axios({
    url: fullUrl,
    headers: query.headers,
    method,
    data: query.body,
  })
    .then(parseResponse)
    .then(setToken)
    .catch(data => {
      if (data.response.status === 401 && BrowserStorage.get('token')) {
        BrowserStorage.remove('token')
        window.location = '/login'

        return
      }

      const error = new Error()
      error.message = data
      throw error
    })
}

const callFileApi = (endpoint, method, params = null) => {
  const apiUrl = getApiUrl()
  const fullUrl = getFullUrl(endpoint, apiUrl)

  const query = {
    method,
    headers: {},
  }

  const token = BrowserStorage.get('token') || null

  if (!isNull(token)) {
    query.headers.Authorization = `Token ${token}`
  }

  const formData = new FormData()

  if (params.dataUrl) {
    formData.append('origin_dataurl', params.dataUrl)
  } else {
    formData.append('origin', params.origin[0])
  }

  if (Object.prototype.hasOwnProperty.call(params, 'type')) {
    formData.append('type', params.type)
  }

  if (Object.prototype.hasOwnProperty.call(params, 'name')) {
    formData.append('name', params.name)
  }

  if (Object.prototype.hasOwnProperty.call(params, 'parent')) {
    formData.append('parent', params.parent)
  }

  query.body = formData

  return axios({
    url: fullUrl,
    headers: query.headers,
    method,
    data: query.body,
  })
    .then(parseResponse)
    .then(setToken)
    .catch(data => {
      if (data.response.status === 401 && BrowserStorage.get('token')) {
        BrowserStorage.remove('token')
        window.location = '/login'

        return
      }

      const error = new Error()
      error.message = data
      throw error
    })
}

export const fileUploadNew = (url, file) => {
  return axios({
    headers: { 'Content-Type': 'multipart/form-data' },
    url,
    method: 'PUT',
    data: file.origin[0],
  })
    .then(parseResponse)
    .then(setToken)
    .catch(data => {
      if (data.response.status === 401 && BrowserStorage.get('token')) {
        BrowserStorage.remove('token')
        window.location = '/login'

        return
      }

      const error = new Error()
      error.message = data
      throw error
    })
}

export const postApi = (endpoint, data, headers) =>
  callApi(endpoint, 'POST', data, headers)
export const patchApi = (endpoint, data) => callApi(endpoint, 'PATCH', data)
export const putApi = (endpoint, data) => callApi(endpoint, 'PUT', data)

export const getApi = (endpoint, params, headers, duplicateParams) =>
  callApi(endpoint, 'GET', params, headers, duplicateParams)

export const getTxtApi = endpoint => callTxtApi(endpoint)

export const deleteApi = (endpoint, data) => callApi(endpoint, 'DELETE', data)

export const fileUpload = (endpoint, params) =>
  callFileApi(endpoint, 'POST', params)
export const patchRaw = (endpoint, params) =>
  callApiRaw(endpoint, 'PATCH', params)
export const putRaw = (endpoint, params) => callApiRaw(endpoint, 'PUT', params)
export const postRaw = (endpoint, params) =>
  callApiRaw(endpoint, 'POST', params)
export const deleteRaw = (endpoint, params) =>
  callApiRaw(endpoint, 'DELETE', params)
