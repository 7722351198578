// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getRequestPipeline,
  getArchivedPipelinesCount,
} from '../../../../core/api/api.pipeline'
import { useSingleLoad } from '../../../../hooks/useSingleLoad/useSingleLoad'
import Phase from './Phase'
import SelectCustom from '../../../../components/Select/SelectCustom'

import classnames from 'classnames'
import styles from './RequestPipeline.module.scss'
import PipelinesModal from './PipelinesModal'

type Props = {
  reload: boolean,
  requestId: number,
  setPipelineSwitched: boolean => void,
}

const RequestPipeline = (props: Props): Node => {
  const { requestId, reload: isReload } = props

  const { t } = useTranslation('Activities')

  const [data, , , reload] = useSingleLoad(getRequestPipeline, requestId)

  const [archivedCount, setArchivedCount] = useState(null)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    initArchivedCount()
  }, [])

  useEffect(() => {
    if (isReload) {
      reload()
      initArchivedCount()
      props.setPipelineSwitched(true)
    }
  }, [isReload])

  const initArchivedCount = () => {
    getArchivedPipelinesCount(requestId).then(data =>
      setArchivedCount(data.count)
    )
  }

  const handleRestore = () => {
    reload()
    props.setPipelineSwitched(true)
  }

  if (!data || !data.length) {
    return null
  }

  const phaseWidth = 100 / data.length

  const getOptions = () => [
    {
      value: 'restorePipeline',
      label: t('Restore'),
      handler: () => setModal(true),
      icon: 'restore',
    },
  ]

  const closeModal = () => setModal(false)

  const pipelineClass = classnames(styles.pipeline, {
    [styles.withArchived]: archivedCount,
  })

  return (
    <>
      <div className={pipelineClass}>
        {data.map(({ id, name, progress }) => (
          <Phase
            name={name}
            key={id}
            phaseWidth={phaseWidth}
            progress={progress}
          />
        ))}
        {archivedCount ? (
          <div className={styles.actions}>
            <SelectCustom
              options={getOptions()}
              onChange={opt => opt.handler()}
            />
          </div>
        ) : null}
      </div>
      {modal && (
        <PipelinesModal
          requestId={requestId}
          onRestore={handleRestore}
          onClose={closeModal}
        />
      )}
    </>
  )
}

export default RequestPipeline
